.pokemonList {
  padding-top: 6.5rem;
  min-height: 100vh;
  /* background-color: #fafafa; */
}

.navbar {
  display: inline;
  background-color: #ffcb05 !important;
  align-items: center;
  /* background-color: #ef5350 !important; */
}

.pokeball:hover,
.goBack:hover {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  filter: drop-shadow(0 0 0.3rem #ee6352);
}

/* Search Bar CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --clr-primary: #003a70;
  --clr-body: #003a70;
  --clr-bg: #ddd;
}

/* body {
  font-family: basic-sans, sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125em;
  line-height: 1.6;
  color: var(--clr-body);
  background: var(--clr-bg);
} */

.searchBox {
  --size: 36px;
  border: 2px solid var(--clr-primary);
  background: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 100px;
  overflow: hidden;
  font-size: 1rem;
  position: relative;
  width: var(--size);
  height: var(--size);
  transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  padding: 3px;
}

.searchText {
  border: 0;
  padding: 0.25em 1em;
  flex-grow: 1;
  outline: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  opacity: 0;
  cursor: pointer;
}

.searchBtn {
  font-size: 1rem;
  margin-left: auto;
  background: 0;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: background 200ms ease-out;
  width: calc(var(--size) - 10px);
  height: calc(var(--size) - 10px);
  color: var(--clr-body);
}

.searchBox:focus-within {
  width: 63%;
}

.searchBox:focus-within .searchText {
  opacity: 1;
  z-index: initial;
  cursor: initial;
  width: calc(100% - var(--size));
}

.searchBox:focus-within .searchBtn {
  background: var(--clr-primary);
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.searchBox:focus-within .searchBtn:hover,
.searchBox:focus-within .searchBtn:focus {
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.65);
}

/* ========= Media Queries (Small Devices) ========= */
@media screen and (max-width: 600px) {
  .searchBox:focus-within {
    width: 240%;
  }
}
